import { UI, GridGallery } from "../../../../content/posts/components/uxui.jsx";
import desktop from "../../../../content/posts/2018-09-24--projekt-mowa/projektMowaDesk.png";
import mobile from "../../../../content/posts/2018-09-24--projekt-mowa/projektMowaMobile.png";
import * as React from 'react';
export default {
  UI,
  GridGallery,
  desktop,
  mobile,
  React
};