import { AvgServerChart, MinMaxServerChart } from "../../../../content/posts/components/Charts/barCharts";
import { ScatterChart } from "../../../../content/posts/components/Charts/scatterCharts";
import { FunnelTime } from "../../../../content/posts/components/Charts/funnelCharts";
import "../../../../content/posts/components/Charts/charts.css";
import { avg, minMaxHtml, minMaxImage } from "../../../../content/posts/2024-03-22--static-race/data";
import actixData from "../../../../content/posts/2024-03-22--static-race/actix.json";
import expressData from "../../../../content/posts/2024-03-22--static-race/express.json";
import nginxData from "../../../../content/posts/2024-03-22--static-race/nginx.json";
import restifyData from "../../../../content/posts/2024-03-22--static-race/restify.json";
import * as React from 'react';
export default {
  AvgServerChart,
  MinMaxServerChart,
  ScatterChart,
  FunnelTime,
  avg,
  minMaxHtml,
  minMaxImage,
  actixData,
  expressData,
  nginxData,
  restifyData,
  React
};