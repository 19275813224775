import { TreeChart } from "../../../../content/posts/components/treechart.jsx";
import { TreeChartChanger } from "../../../../content/posts/components/treeChartChanger.jsx";
import { ComplexityChart } from "../../../../content/posts/components/Charts/complexityChart.jsx";
import { LineChartComplexity } from "../../../../content/posts/components/Charts/lineChart.jsx";
import nextjs from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/nextjs.json";
import react from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/react.json";
import angular from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/angular.json";
import nest from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/nest.json";
import svelte from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/svelte.json";
import vue from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/vue.json";
import vueStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/vue-starter.json";
import reactStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/react-starter.json";
import angularStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/angular-starter.json";
import svelteStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/svelte-starter.json";
import nestStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/nest-starter.json";
import nextjsStarter from "../../../../content/posts/2024-06-10--project-folder-structure/data/trees/nextjs-blog.json";
import complexityScores from "../../../../content/posts/2024-06-10--project-folder-structure/data/complexityScoresProjects.json";
import complexityScoresStarters from "../../../../content/posts/2024-06-10--project-folder-structure/data/complexityScoresStarters.json";
import complexityGraphData from "../../../../content/posts/2024-06-10--project-folder-structure/data/complexityGraphData.json";
import * as React from 'react';
export default {
  TreeChart,
  TreeChartChanger,
  ComplexityChart,
  LineChartComplexity,
  nextjs,
  react,
  angular,
  nest,
  svelte,
  vue,
  vueStarter,
  reactStarter,
  angularStarter,
  svelteStarter,
  nestStarter,
  nextjsStarter,
  complexityScores,
  complexityScoresStarters,
  complexityGraphData,
  React
};